export const optionsTypePayment = {
  1: { value: 1, label: 'Boleto Bancário' },
  2: { value: 2, label: 'Cartão de Crédito'},
  3: { value: 3, label: 'Pergunte ao Cliente' },
  4: { value: 4, label: 'Pix' },
}

export const optionsDayCharge = {
  1: { value: 1, label: '01' },
  2: { value: 2, label: '02' },
  3: { value: 3, label: '03' },
  4: { value: 4, label: '04' },
  5: { value: 5, label: '05' },
  6: { value: 6, label: '06' },
  7: { value: 7, label: '07' },
  8: { value: 8, label: '08' },
  9: { value: 9, label: '09' },
  10: { value:10, label: '10' },
  11: { value:11, label: '11' },
  12: { value:12, label: '12' },
  13: { value: 13, label: '13' },
  14: { value: 14, label: '14' },
  15: { value: 15, label: '15' },
  16: { value: 16, label: '16' },
  17: { value: 17, label: '17' },
  18: { value: 18, label: '18' },
  19: { value: 19, label: '19' },
  20: { value: 20, label: '20' },
  21: { value: 21, label: '21' },
  22: { value: 22, label: '22' },
  23: { value: 23, label: '23' },
  24: { value: 24, label: '24' },
  25: { value: 25, label: '25' },
  26: { value: 26, label: '26' },
  27: { value: 27, label: '27' },
  28: { value: 28, label: '28' },
  29: { value: 29, label: '29' },
  30: { value: 30, label: '30' },
  31: { value: 31, label: '31' }
}

export const optionsCycle = {
  1: { value: 1, label: 'Semanal' },
  2: { value: 2, label: 'Quinzenal' },
  3: { value: 3, label: 'Mensal' },
  4: { value: 4, label: 'Bimestral' },
  5: { value: 5, label: 'Trimestral' },
  6: { value: 6, label: 'Semestral' },
  7: { value: 7, label: 'Anual' }
}

export const optionsInstallments = {
  1: { value: 1, label: 'À vista' },
  2: { value: 2, label: 'Em até 2x' },
  3: { value: 3, label: 'Em até 3x' },
  4: { value: 4, label: 'Em até 4x' },
  5: { value: 5, label: 'Em até 5x' },
  6: { value: 6, label: 'Em até 6x' },
  7: { value: 7, label: 'Em até 7x' },
  8: { value: 8, label: 'Em até 8x' },
  9: { value: 9, label: 'Em até 9x' },
  10: { value: 10, label: 'Em até 10x' },
  11: { value: 11, label: 'Em até 11x' },
  12: { value: 12, label: 'Em até 12x' },
}

export const optionsDaysToExpiration = {
  1: { value: 1, label: '1' },
  2: { value: 2, label: '2' },
  5: { value: 5, label: '5' },
  10: { value: 10, label: '10' },
  20: { value: 20, label: '20' },
  30: { value: 30, label: '30' },
  45: { value: 45, label: '45' },
}

export const optionsTypeTransfer = {
  1: { value: 1, label: 'TED' },
  2: { value: 2, label: 'DOC' },
  3: { value: 3, label: 'PIX' },
}

export const optionsTypeExtratPeriod = {
  1: { value: 1, label: 'Hoje' },
  2: { value: 2, label: 'Últimos 7 dias' },
  3: { value: 3, label: 'Últimos 15 dias' },
  4: { value: 4, label: 'Últimos 30 dias' },
  5: { value: 5, label: 'Personalizado' }
}

export const optionsTypePeriodDashboard = {
  1: { value: 1, label: 'Este Mês' },
  2: { value: 2, label: 'Este Ano' },
  3: { value: 3, label: 'Personalizado' },
}

export const optionsTypeDate = {
  1: { value: 1, label: 'Data de Vencimento' },
  2: { value: 2, label: 'Data de Recebimento' },
  3: { value: 3, label: 'Data de Criação' },
}

export const optionsTypeCharge = {
  1: { value: 1, label: 'Avulsas' },
  2: { value: 2, label: 'Parceladas' },
  3: { value: 3, label: 'Recorrentes (Assinaturas)' }
}

export const optionsGroupBillingBy = {
  1: { value: 1, label: 'Por Mês' },
  2: { value: 2, label: 'Por Dia' }
}

export const optionsTypeCompany = {
  1: { value: 1, label: 'Física' },
  2: { value: 2, label: 'Jurídica' }
}

export const optionsCompanyType = {
  1: { value: 1, label: 'Firma Individual' },
  2: { value: 2, label: 'Micro Empreendedor Individual' },
  3: { value: 3, label: 'Empresa Limitada' },
  4: { value: 4, label: 'Associação' }
}

export const optionsTypeAccount = {
  1: { value: 1, label: 'Conta Corrente' },
  2: { value: 2, label: 'Conta Poupança' }
}

export const optionsTypeDestinyAccount = {
  1: { value: 1, label: 'Minha conta' },
  2: { value: 2, label: 'Conta de terceiro' }
}

export const optionsLevelUsers = {
  1: { value: 1, label: 'Administrador (Acesso total)' },
  2: { value: 2, label: 'Financeiro (Acesso total menos Usuários, Integrações e Google Authenticator)' },
  3: { value: 3, label: 'Vendedor (Somente getão de Clientes e Cobranças)' },
}

export const optionsOverdueCharges = {
  1: { value: 1, label: 'Com cobranças vencidas' },
  2: { value: 2, label: 'Sem cobranças vencidas' }
}

export const optionsYesOrNot = {
  0: { value: 0, label: 'Não' },
  1: { value: 1, label: 'Sim' },
}

export const optionsTypePaymentLink = {
  1: { value: 1, label: 'À vista ou parcelado' },
  2: { value: 2, label: 'Assinatura' }
}

export const optionsPaymentBy = {
  1: { value: 1, label: 'Boleto Bancário' },
  2: { value: 2, label: 'Cartão de Crédito' },
  3: { value: 4, label: 'PIX' },
}

export const optionsReceiptBy = {
  1: { value: 1, label: 'Boleto Bancário' },
  2: { value: 2, label: 'Cartão de Crédito' },
  3: { value: 3, label: 'Cartão de Débito' },
  4: { value: 4, label: 'Recebido em Dinheiro' }
}

export const optionsTypeReceipt = {
  0: { value: 0, label: 'Comprovante de agendamento' },
  1: { value: 1, label: 'Comprovante de pagamento' },
}

export const optionsToBeWon = {
  5: { value: 5, label: '5 dias' },
  10: { value: 10, label: '10 dias' },
  15: { value: 15, label: '15 dias' },
  30: { value: 30, label: '30 dias' },
}

export const optionsRememberOverdue = {
  1: { value: 1, label: '1 dia' },
  7: { value: 7, label: '7 dias' },
  15: { value: 15, label: '15 dias' },
  30: { value: 30, label: '30 dias' },
}

export const optionsTypeConsultSerasa = {
  1: { value: 1, label: 'Crednet Light'},
  2: { value: 2, label: 'Crednet Top'},
  3: { value: 3, label: 'Concentre Top Score'},
  4: { value: 4, label: 'Credit Bureau Top Score'}
}

export const transferTypes ={
  1: { value: 1, label: 'TED' },
  3: { value: 3, label: 'PIX' },
}

export const pixKeyTypes ={
  1: { value: 1, label: 'Celular' },
  2: { value: 2, label: 'E-mail' },
  3: { value: 3, label: 'CPF / CNPJ' },
  4: { value: 4, label: 'Chave Aleatória' },
}

export const optionsFormats = {
  1: { value: 1, label: 'PDF' },
  2: { value: 2, label: 'CSV' }
}

export const optionsCancelProtest = {
  0: { value: 1, label: 'Pagamento da dívida' },
  1: { value: 2, label: 'Renegociação da dívida' },
  2: { value: 3, label: 'Por solicitação do cliente' },
  3: { value: 4, label: 'Ordem Judicial' },
  4: { value: 5, label: 'Correção de dados' },
}

export const optionsStatusProtest = {
  1: { value: 1, label: 'Negativação em processamento' },
  2: { value: 2, label: 'Negativação enviada' },
  3: { value: 3, label: 'Negativação aceita' },
  4: { value: 4, label: 'Negativação aguardando correção' },
  5: { value: 5, label: 'Negativação rejeitada' },
  6: { value: 6, label: 'Baixa de uma negativação ativa enviada' },
  7: { value: 7, label: 'Negativação baixada' },
}
