import Echo from "laravel-echo";
import { useState } from "react";
import ConfigWS from "../../../config/configWS";

  const Websocket = ({ data, retrieve, callbackUpdateSituation }) => {
    if (!window.Echo) {
      window.Echo = new Echo(ConfigWS);
    }

    let chargePixId = "";
    let inititalSituation = "ATIVA";
    if (data?.charges && data.charges.length > 0) {
      chargePixId = data.charges[0].pix.id;
      inititalSituation = data.charges[0].pix.situation;
    } else if (data?.pix?.id) {
      chargePixId = data.pix.id;
      inititalSituation = data.pix.situation;
    } else if (data?.pixCharge?.id) {
      chargePixId = data.pixCharge.id;
      inititalSituation = data.pixCharge.situation;
    }

    const [paymentLinkStatus, setPaymentLinkStatus] = useState(retrieveFormatedStatus(inititalSituation));


    //Retorna o status em uma mensagem formatada para o usuário ao invés de status iguais do banco de dados
    function retrieveFormatedStatus(situation) {
      switch (situation) {
        case "CONCLUIDA":
          callbackUpdateSituation(situation);
          return "Pagamento Aprovado!";
        case "ATIVA":
          return "Aguardando Pagamento...";
        case "REMOVIDA_PELO_USUARIO_RECEBEDOR":
        case "REMOVIDA_PELO_PSP":
          return "Pagamento cancelado, consulte o vendedor para mais informações";
        default:
          return situation;
      }
    };


   window.Echo.channel(`charge_pix_check_status.${chargePixId}`)
      .listen("ChargePixCheckStatus", (props) => {
        setPaymentLinkStatus(retrieveFormatedStatus(props.situation));
      })
      .error((e) => console.log(e));

  return (
    <>
    {paymentLinkStatus}
    </>
  );
};

export default Websocket;
